:root {
  /* color palette */
  --base-color: #73CBD0;
  --base-color-s1: #110a04;
  --base-color-s2: #24B7C5;
  --base-color-s3: #1C6267;

}

/* 
    font-family: 'Montserrat', sans-serif;
    font weights 700 and 400
    font-family: 'PT Serif', serif;
*/ 

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  /* font-weight: 500 !important; */
  color: var(--base-color-s1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--base-color) !important;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'PT Serif', serif !important;
  font-weight: 700 !important;
}

.container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  /* background: #fff; */
  border-radius: 3px;
}

/* Result Component */
.result {
  padding: 1.5rem 2.5rem;
}

/* Quiz Component */
.opcoes {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* QuestionCount Component */
.question-count {
  /*padding: 1.5rem 2.5rem 0;*/
  font-size: 0.625em !important;
  text-align: left;
}

/* Question Component */
.pergunta {
  margin: 0;
  font-size: 1.3125em;
  /* padding: 0.5rem 2.5rem 1.5rem 2.5rem; */
}

@media only screen and (max-width: 500px) {
  .pergunta {
    /*font-size: 21px;*/
  }
}


.pergunta-header-top {
  font-weight: bolder;
  font-size: 1.2em !important;
  text-align: left;
  font-family: 'PT Serif', serif !important;
}
.pergunta-header {
  font-weight: 600 !important;
  text-align: left;
  margin: 2em 1em 1em 1em;
}

/* AnswerOption Component */
.opcao-texto {
  font-size: 1.0em;
}

.opcao:hover {
  background-color: #eefbfe;
  transition: 0.377ms;
}

.radioCustomButton {
  position: absolute;
  width: auto;
  opacity: 0;
}

.radioCustomButton,
.radioCustomLabel {
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.radioCustomLabel {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 1ex 2.5rem 1ex 2.7em;
  font-size: 1em;
  line-height: 1;
  text-align: left;
}

.radioCustomButton ~ .radioCustomLabel:before {
  position: absolute;
  top: 7px;
  left: 1em;
  width: 28px;
  height: 28px;
  content: '';
  display: inline-block;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 50%;
  transition: all 0.3s;
}

.radioCustomButton:checked ~ .radioCustomLabel:before {
  content: '';
  background: #8bc53f url(./svg/icon-check.svg) no-repeat;
  background-size: 27px;
  border-color: #8bc53f;
}

/* Animation */
.fade-enter {
  opacity: 0;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.3s;
}

.fade-leave {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-appear {
  opacity: 0;
}

.fade-appear.fade-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.background-image {
  position: absolute;
  left: 0;
  top: -60px;

  max-width: 500px;
  height: auto;
  /*top: 40vh;*/
  padding: 0px 0px 0px 0px;
  /* top right bottom left */
  z-index: -1;

}

.comentario, .resultado {
  margin-top: 3ex;
}

.logo {
  max-width: 400px;
}

@media only screen and (max-width: 400px) {
  .logo {
    width: 80vw;
  }
}

.button {
  position: relative;
  cursor: pointer;
  max-width: 140px;
  z-index: 8888;
}

.acertos {
  font-size: 1.3em;
}

.centralizado {
  /* display: flex; */
  justify-content: center;
  text-align: center;
}

.acerto-img {
  width: 140px;
} 

.comentario-texto {
  padding: 0 10px 0 10px;
  font-size: 1.125em;
}

.texto-verde{
  color: green;
}

.texto-vermelho{
  color: red;
}

.voltar-button{
  z-index: 2;
}

.comentario-header {
  /* padding: 0 10px 0 10px; */
  margin-bottom: 1em;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;  
  font-size: 1.5em;
  text-align: center;
}

.comentario-num-wrapper {
  width: 80%;
  top: 1.25em;
  margin-right: auto;
  margin-left: auto;
  padding: 0 2ex .8ex 2ex;
}

.comentario-num-left {
  float: left;
  font-weight: bold;
}

.comentario-num-right {
  float: right;
  font-weight: bold;
}

.progress-bar {
 background-color: var(--base-color-s3) !important; 
}

/* .progress {
  margin: 27px 108px 29px 132px;
} */

.progresso{
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}

.home-text {
  text-align: center;
  font-size: 1.1250em;
}

.home-aviso-tempo{
  width: 100%;
  display: block;
}

.home-convite{
  width: 100%;
  display: block;
  font-weight: bolder;
}

.button-home{
  margin-bottom: 3em;
}

.share-container {
  position: relative;
  margin-top: 1em;
}

.share-container > .react-share__ShareButton{
  vertical-align: middle;
}

.share-container-buttons > .react-share__ShareButton{
margin: .5em;
}

.compartilhe-text{
  display: inline-block;
  /* padding-top: px; */
  font-weight: bold;
}


/* menu and layout */
.ham {
  position: relative;
  display: flex;
  border-style: none;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  z-index: 9998;
}

.burger_1,
.burger_2,
.burger_3 {
  position: absolute;
  width: 25px;
  height: 3px;
  background: var(--base-color-s1);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
  z-index: 9999;
}


.burger_2 {
  top: 15px;
  /*transform: translateY(-9px);*/
}

.burger_3 {
  bottom: 15px;
  /*transform: translateY(5px);*/
}

.sticky-navbar {
  position: sticky;
  margin-bottom: 110px;
}

.navbar {
  position: relative;
  width: 100vw;
  height: 110px;
  display: inline-block;
}

.nav-items {
  position: absolute;
  display: inline-block;
  left: 60px;
  top: 80px;
}

.nav-social {
  position: absolute;
  display: inline-block;
  top: 60px;
  left: 50px;
}

nav a {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
}

.credits-fadeIn {
  opacity: 1;
  height: 200vh;
  margin-bottom: 200px;
  
  /*background-color: var(--base-color);*/
  background-color: transparent;
  transition: opacity 0.277s;
  position: absolute;
  z-index: 9997;
}

.credits-fadeOut {
  opacity:0;
  height:0;
  z-index: 0;
  transition: opacity 0.277s;
}

.hamburger {
  position: absolute !important;
  right: 20px;
  top: 20px;
  margin-right: 0; /* golden css time */
  margin-left: auto;
  z-index: 9999;
  cursor: pointer;
}

.hamburger:after {
  content: "SOBRE";
  position: relative;
  top: 20px;
  right: 7px;
  font-size: 0.7em !important;
  font-family: 'Montserrat', sans-serif;
  color: var(--base-color-s3);
  opacity: 0.4;
  transition: 0.277ms;
}

.closed:after {
  content: "VOLTAR";
  position: relative;
  top: 20px;
  right: 12px;
  font-size: 0.7em !important;
  /*font-size: 0.7em !important;*/
  font-family: 'Montserrat', sans-serif;
  color: var(--base-color-s3);
  opacity: 0.4;
  transition: 0.277ms;
}

.logo-credit {
  width: 13em;
  margin: 0.7em 1.2em;
}

.tinier {
  width: 10em;
}

.credits-header {
  /* position: relative; */
  font-size: 2em;
  font-weight: bold;
  padding-top: 2em;
  /*left: 50px;*/
}

.credits-bulk {
  position: relative;
  top: 39px;
}

.credits-bulk-right {
  position: relative;
  display: block;
  text-align: left;
  left: 50px;
  margin-top: 50px;
  
}

.right-bold {
  position: relative;
}

@media (max-width: 440px) {
  .credits-bulk {
    position: relative;
    top: 20px;
    /*
    left: -50px;
    */
  }
  
  .credits-bulk-right {
    position: relative;
    display: block;
    text-align: left;
    left: 0px;
  }
}

.credits-z {
  position: relative;
  z-index: 7777 !important;
}

.sobre-button {
  background-color: transparent;
  border: none;
  /* position: absolute; */
  float: right;
  cursor: pointer;
  z-index: 2;
  display: block;
  padding-top: 1.5em;
}

.voltar {
  z-index: 9999;
}

img.logo-pesc{
  width: 7em;
}

@media (min-width: 769px) {
  .container {
    margin: 2.5rem auto;
  }

  .sobre-button{
    padding-top: 1em;
  }
}